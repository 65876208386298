import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Title from "../Title";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useSettings from "../../hooks/useSettings";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { Tabs, Tab } from "@material-ui/core";
import OnlyForSuperUser from "../../components/OnlyForSuperUser";
import useAuth from "../../hooks/useAuth.js";

//import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    backgroundColor: theme.palette.options, //DARK MODE PLW DESIGN//
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: theme.palette.fontecor,
    }, //DARK MODE PLW DESIGN//
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
}));

export default function Options(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();

  const [currentUser, setCurrentUser] = useState({});
  const { getCurrentUserInfo } = useAuth();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function findData() {
      setLoading(true);
      try {
        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e);
      }
      setLoading(false);
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSuper = () => {
    return currentUser.super;
  };

  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");

  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [loadingCheckMsgIsGroup, setCheckMsgIsGroup] = useState(false);

  const [viewclosed, setviewclosed] = useState("disabled");
  const [loadingviewclosed, setLoadingviewclosed] = useState(false);

  const [viewgroups, setviewgroups] = useState("disabled");
  const [loadingviewgroups, setLoadingviewgroups] = useState(false);

  const [ipixcType, setIpIxcType] = useState("");
  const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
  const [tokenixcType, setTokenIxcType] = useState("");
  const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);

  const [ipmkauthType, setIpMkauthType] = useState("");
  const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] =
    useState(false);
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] =
    useState(false);

  const [asaasType, setAsaasType] = useState("");
  const [loadingAsaasType, setLoadingAsaasType] = useState(false);

  // recursos a mais...
  const [trial, settrial] = useState("3");
  const [loadingtrial, setLoadingtrial] = useState(false);

  const [viewregister, setviewregister] = useState("disabled");
  const [loadingviewregister, setLoadingviewregister] = useState(false);

  const [allowregister, setallowregister] = useState("disabled");
  const [loadingallowregister, setLoadingallowregister] = useState(false);

  const [SendGreetingAccepted, setSendGreetingAccepted] = useState("disabled");
  const [loadingSendGreetingAccepted, setLoadingSendGreetingAccepted] =
    useState(false);

  const [SettingsTransfTicket, setSettingsTransfTicket] = useState("disabled");
  const [loadingSettingsTransfTicket, setLoadingSettingsTransfTicket] =
    useState(false);

  const [sendGreetingMessageOneQueues, setSendGreetingMessageOneQueues] =
    useState("disabled");
  const [
    loadingSendGreetingMessageOneQueues,
    setLoadingSendGreetingMessageOneQueues,
  ] = useState(false);

  const { update } = useSettings();

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRating = settings.find((s) => s.key === "userRating");
      if (userRating) {
        setUserRating(userRating.value);
      }
      const scheduleType = settings.find((s) => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }
      const callType = settings.find((s) => s.key === "call");
      if (callType) {
        setCallType(callType.value);
      }
      const CheckMsgIsGroup = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {
        setCheckMsgIsGroupType(CheckMsgIsGroup.value);
      }

      const allowregister = settings.find((s) => s.key === "allowregister");
      if (allowregister) {
        setallowregister(allowregister.value);
      }

      const viewclosed = settings.find((s) => s.key === "viewclosed");
      if (viewclosed) {
        setviewclosed(viewclosed.value);
      }

      const viewgroups = settings.find((s) => s.key === "viewgroups");
      if (viewgroups) {
        setviewgroups(viewgroups.value);
      }

      {
        /*PLW DESIGN SAUDAÇÃO*/
      }
      const SendGreetingAccepted = settings.find(
        (s) => s.key === "sendGreetingAccepted"
      );
      if (SendGreetingAccepted) {
        setSendGreetingAccepted(SendGreetingAccepted.value);
      }
      {
        /*PLW DESIGN SAUDAÇÃO*/
      }

      {
        /*TRANSFERIR TICKET*/
      }
      const SettingsTransfTicket = settings.find(
        (s) => s.key === "sendMsgTransfTicket"
      );
      if (SettingsTransfTicket) {
        setSettingsTransfTicket(SettingsTransfTicket.value);
      }
      {
        /*TRANSFERIR TICKET*/
      }

      const viewregister = settings.find((s) => s.key === "viewregister");
      if (viewregister) {
        setviewregister(viewregister.value);
      }

      const sendGreetingMessageOneQueues = settings.find(
        (s) => s.key === "sendGreetingMessageOneQueues"
      );
      if (sendGreetingMessageOneQueues) {
        setSendGreetingMessageOneQueues(sendGreetingMessageOneQueues.value);
      }

      const chatbotType = settings.find((s) => s.key === "chatBotType");
      if (chatbotType) {
        setChatbotType(chatbotType.value);
      }

      const trial = settings.find((s) => s.key === "trial");
      if (trial) {
        settrial(trial.value);
      }

      const ipixcType = settings.find((s) => s.key === "ipixc");
      if (ipixcType) {
        setIpIxcType(ipixcType.value);
      }

      const tokenixcType = settings.find((s) => s.key === "tokenixc");
      if (tokenixcType) {
        setTokenIxcType(tokenixcType.value);
      }

      const ipmkauthType = settings.find((s) => s.key === "ipmkauth");
      if (ipmkauthType) {
        setIpMkauthType(ipmkauthType.value);
      }

      const clientidmkauthType = settings.find(
        (s) => s.key === "clientidmkauth"
      );
      if (clientidmkauthType) {
        setClientIdMkauthType(clientidmkauthType.value);
      }

      const clientsecretmkauthType = settings.find(
        (s) => s.key === "clientsecretmkauth"
      );
      if (clientsecretmkauthType) {
        setClientSecrectMkauthType(clientsecretmkauthType.value);
      }

      const asaasType = settings.find((s) => s.key === "asaas");
      if (asaasType) {
        setAsaasType(asaasType.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  async function handleChangeUserRating(value) {
    setUserRating(value);
    setLoadingUserRating(true);
    await update({
      key: "userRating",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingUserRating(false);
  }

  async function handleallowregister(value) {
    setallowregister(value);
    setLoadingallowregister(true);
    await update({
      key: "allowregister",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingallowregister(false);
  }

  async function handleviewclosed(value) {
    setviewclosed(value);
    setLoadingviewclosed(true);
    await update({
      key: "viewclosed",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingviewclosed(false);
  }

  async function handleviewgroups(value) {
    setviewgroups(value);
    setLoadingviewgroups(true);
    await update({
      key: "viewgroups",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingviewgroups(false);
  }
  async function handleSendGreetingMessageOneQueues(value) {
    setSendGreetingMessageOneQueues(value);
    setLoadingSendGreetingMessageOneQueues(true);
    await update({
      key: "sendGreetingMessageOneQueues",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingSendGreetingMessageOneQueues(false);
  }

  async function handleviewregister(value) {
    setviewregister(value);
    setLoadingviewregister(true);
    await update({
      key: "viewregister",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingviewregister(false);
  }

  async function handletrial(value) {
    settrial(value);
    setLoadingtrial(true);
    await update({
      key: "trial",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingtrial(false);
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await update({
      key: "scheduleType",
      value,
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light",
    });
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  async function handleCallType(value) {
    setCallType(value);
    setLoadingCallType(true);
    await update({
      key: "call",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingCallType(false);
  }

  async function handleChatbotType(value) {
    setChatbotType(value);
    setLoadingChatbotType(true);
    await update({
      key: "chatBotType",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingChatbotType(false);
  }

  async function handleGroupType(value) {
    setCheckMsgIsGroupType(value);
    setCheckMsgIsGroup(true);
    await update({
      key: "CheckMsgIsGroup",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setCheckMsgIsGroupType(false);
    /*     if (typeof scheduleTypeChanged === "function") {
          scheduleTypeChanged(value);
        } */
  }

  {
    /*NOVO CÓDIGO*/
  }
  async function handleSendGreetingAccepted(value) {
    setSendGreetingAccepted(value);
    setLoadingSendGreetingAccepted(true);
    await update({
      key: "sendGreetingAccepted",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingSendGreetingAccepted(false);
  }

  {
    /*NOVO CÓDIGO*/
  }

  async function handleSettingsTransfTicket(value) {
    setSettingsTransfTicket(value);
    setLoadingSettingsTransfTicket(true);
    await update({
      key: "sendMsgTransfTicket",
      value,
    });

    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingSettingsTransfTicket(false);
  }

  async function handleChangeIPIxc(value) {
    setIpIxcType(value);
    setLoadingIpIxcType(true);
    await update({
      key: "ipixc",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingIpIxcType(false);
  }

  async function handleChangeTokenIxc(value) {
    setTokenIxcType(value);
    setLoadingTokenIxcType(true);
    await update({
      key: "tokenixc",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingTokenIxcType(false);
  }

  async function handleChangeIpMkauth(value) {
    setIpMkauthType(value);
    setLoadingIpMkauthType(true);
    await update({
      key: "ipmkauth",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingIpMkauthType(false);
  }

  async function handleChangeClientIdMkauth(value) {
    setClientIdMkauthType(value);
    setLoadingClientIdMkauthType(true);
    await update({
      key: "clientidmkauth",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingClientIdMkauthType(false);
  }

  async function handleChangeClientSecrectMkauth(value) {
    setClientSecrectMkauthType(value);
    setLoadingClientSecrectMkauthType(true);
    await update({
      key: "clientsecretmkauth",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingClientSecrectMkauthType(false);
  }

  async function handleChangeAsaas(value) {
    setAsaasType(value);
    setLoadingAsaasType(true);
    await update({
      key: "asaas",
      value,
    });
    toast.success("Operação atualizada com sucesso.", {
      style: {
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(180, 76, 240)",
        fontWeight: "bold",
        border: "1px solid rgb(180, 76, 240)",
        borderRadius: "10px",
      },
    });
    setLoadingAsaasType(false);
  }
  return (
    <>
      <Grid spacing={3} container>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="ratings-label">Avaliações</InputLabel>
            <Select
              labelId="ratings-label"
              value={userRating}
              onChange={async (e) => {
                handleChangeUserRating(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
              <MenuItem value={"enabled"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="schedule-type-label">
              Gerenciamento de Expediente
            </InputLabel>
            <Select
              labelId="schedule-type-label"
              value={scheduleType}
              onChange={async (e) => {
                handleScheduleType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"queue"}>Fila</MenuItem>
              <MenuItem value={"company"}>Empresa</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="group-type-label">
              Ignorar Mensagens de Grupos
            </InputLabel>
            <Select
              labelId="group-type-label"
              value={CheckMsgIsGroup}
              onChange={async (e) => {
                handleGroupType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desativado</MenuItem>
              <MenuItem value={"enabled"}>Ativado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="call-type-label">Aceitar Chamada</InputLabel>
            <Select
              labelId="call-type-label"
              value={callType}
              onChange={async (e) => {
                handleCallType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Não Aceitar</MenuItem>
              <MenuItem value={"enabled"}>Aceitar</MenuItem>
            </Select>
            <FormHelperText>
              {loadingCallType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="chatbot-type-label">Tipo Chatbot</InputLabel>
            <Select
              labelId="chatbot-type-label"
              value={chatbotType}
              onChange={async (e) => {
                handleChatbotType(e.target.value);
              }}
            >
              <MenuItem value={"text"}>Texto</MenuItem>
              {/*<MenuItem value={"button"}>Botão</MenuItem>*/}
              {/*<MenuItem value={"list"}>Lista</MenuItem>*/}
            </Select>
            <FormHelperText>
              {loadingChatbotType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* ENVIAR SAUDAÇÃO AO ACEITAR O TICKET */}
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendGreetingAccepted-label">
              Enviar saudação ao aceitar o ticket
            </InputLabel>
            <Select
              labelId="sendGreetingAccepted-label"
              value={SendGreetingAccepted}
              onChange={async (e) => {
                handleSendGreetingAccepted(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"enabled"}>Habilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendGreetingAccepted && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* ENVIAR SAUDAÇÃO AO ACEITAR O TICKET */}

        {/* ENVIAR MENSAGEM DE TRANSFERENCIA DE SETOR/ATENDENTE */}
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendMsgTransfTicket-label">
              Enviar mensagem de transferencia de Fila/agente
            </InputLabel>
            <Select
              labelId="sendMsgTransfTicket-label"
              value={SettingsTransfTicket}
              onChange={async (e) => {
                handleSettingsTransfTicket(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"enabled"}>Habilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSettingsTransfTicket && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* ENVIAR SAUDAÇÃO QUANDO HOUVER SOMENTE 1 FILA */}
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendGreetingMessageOneQueues-label">
              Enviar saudação quando houver somente 1 fila
            </InputLabel>
            <Select
              labelId="sendGreetingMessageOneQueues-label"
              value={sendGreetingMessageOneQueues}
              onChange={async (e) => {
                handleSendGreetingMessageOneQueues(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"enabled"}>Habilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendGreetingMessageOneQueues && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="viewclosed-label">
              Operador Visualiza Tickets Fechados?
            </InputLabel>
            <Select
              labelId="viewclosed-label"
              value={viewclosed}
              onChange={async (e) => {
                handleviewclosed(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Não</MenuItem>
              <MenuItem value={"enabled"}>Sim</MenuItem>
            </Select>
            <FormHelperText>
              {loadingviewclosed && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="viewgroups-label">
              Operador Visualiza Grupos?
            </InputLabel>
            <Select
              labelId="viewgroups-label"
              value={viewgroups}
              onChange={async (e) => {
                handleviewgroups(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Não</MenuItem>
              <MenuItem value={"enabled"}>Sim</MenuItem>
            </Select>
            <FormHelperText>
              {loadingviewgroups && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <OnlyForSuperUser
        user={currentUser}
        yes={() => (
          <>
            <Grid spacing={3} container>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                variant="scrollable"
                className={classes.tab}
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                <Tab label="Configurações Globais" />
              </Tabs>
            </Grid>

            <Grid xs={12} sm={12} md={12} item>
              <FormControl className={classes.selectContainer}>
                <InputLabel id="allowregister-label">
                  Registro (Inscrição) Permitida?
                </InputLabel>
                <Select
                  labelId="allowregister-label"
                  value={allowregister}
                  onChange={async (e) => {
                    handleallowregister(e.target.value);
                  }}
                >
                  <MenuItem value={"disabled"}>Não</MenuItem>
                  <MenuItem value={"enabled"}>Sim</MenuItem>
                </Select>
                <FormHelperText>
                  {loadingallowregister && "Atualizando..."}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid xs={12} sm={12} md={12} item>
              <FormControl className={classes.selectContainer}>
                <InputLabel id="viewregister-label">
                  Registro (Inscrição) Visível?
                </InputLabel>
                <Select
                  labelId="viewregister-label"
                  value={viewregister}
                  onChange={async (e) => {
                    handleviewregister(e.target.value);
                  }}
                >
                  <MenuItem value={"disabled"}>Não</MenuItem>
                  <MenuItem value={"enabled"}>Sim</MenuItem>
                </Select>
                <FormHelperText>
                  {loadingviewregister && "Atualizando..."}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid xs={12} sm={12} md={12} item>
              <FormControl className={classes.selectContainer}>
                <InputLabel id="trial-label">Tempo de Trial?</InputLabel>
                <Select
                  labelId="trial-label"
                  value={trial}
                  onChange={async (e) => {
                    handletrial(e.target.value);
                  }}
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"6"}>6</MenuItem>
                  <MenuItem value={"7"}>7</MenuItem>
                </Select>
                <FormHelperText>
                  {loadingtrial && "Atualizando..."}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
      />
      <Grid spacing={3} container>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
          style={{
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <Tab label="INTEGRAÇÕES" />
        </Tabs>
      </Grid>
      {/*-----------------IXC-----------------*/}
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
        >
          <Tab label="IXC" />
        </Tabs>
        <Grid xs={12} sm={6} md={6} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="ipixc"
              name="ipixc"
              margin="dense"
              label="IP do IXC"
              variant="outlined"
              value={ipixcType}
              onChange={async (e) => {
                handleChangeIPIxc(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingIpIxcType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={6} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="tokenixc"
              name="tokenixc"
              margin="dense"
              label="Token do IXC"
              variant="outlined"
              value={tokenixcType}
              onChange={async (e) => {
                handleChangeTokenIxc(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingTokenIxcType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      {/*-----------------MK-AUTH-----------------*/}
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
        >
          <Tab label="MK-AUTH" />
        </Tabs>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="ipmkauth"
              name="ipmkauth"
              margin="dense"
              label="Ip Mk-Auth"
              variant="outlined"
              value={ipmkauthType}
              onChange={async (e) => {
                handleChangeIpMkauth(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingIpMkauthType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="clientidmkauth"
              name="clientidmkauth"
              margin="dense"
              label="Client Id"
              variant="outlined"
              value={clientidmkauthType}
              onChange={async (e) => {
                handleChangeClientIdMkauth(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingClientIdMkauthType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="clientsecretmkauth"
              name="clientsecretmkauth"
              margin="dense"
              label="Client Secret"
              variant="outlined"
              value={clientsecretmkauthType}
              onChange={async (e) => {
                handleChangeClientSecrectMkauth(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingClientSecrectMkauthType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      {/*-----------------ASAAS-----------------*/}
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
        >
          <Tab label="ASAAS" />
        </Tabs>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="asaas"
              name="asaas"
              margin="dense"
              label="Token Asaas"
              variant="outlined"
              value={asaasType}
              onChange={async (e) => {
                handleChangeAsaas(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingAsaasType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
