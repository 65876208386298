import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from "react-trello";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const jsonString = user.queues.map((queue) => queue.UserQueue.queueId);

  const [file, setFile] = useState({ lanes: [] });
  const [tickets, setTickets] = useState([]);
  const [tags, setTags] = useState([]);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      setTags(fetchedTags);
      // Fetch tickets after fetching tags
      await fetchTickets(jsonString);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const fetchTickets = async (queueIds) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(queueIds),
          teste: true,
        },
      });
      setTickets(data.tickets || []);
    } catch (err) {
      console.error("Error fetching tickets:", err);
      setTickets([]);
    }
  };

  const popularCards = useCallback(() => {
    const filteredTickets = tickets.filter(
      (ticket) => ticket.tags.length === 0
    );

    const lanes = [
      {
        id: "lane0",
        title: i18n.t("Em aberto"),
        label: "0",
        cards: filteredTickets.map((ticket) => ({
          id: ticket.id.toString(),
          label: "Ticket nº " + ticket.id.toString(),
          description: (
            <div>
              <p>
                {ticket.contact.number}
                <br />
                {ticket.lastMessage}
              </p>
              <button
                className={classes.button}
                onClick={() => handleCardClick(ticket.uuid)}
              >
                Ver Ticket
              </button>
            </div>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.uuid,
        })),
      },
      ...tags.map((tag) => {
        const filteredTickets = tickets.filter((ticket) => {
          const tagIds = ticket.tags.map((tag) => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: tag.name,
          label: tag.id.toString(),
          cards: filteredTickets.map((ticket) => ({
            id: ticket.id.toString(),
            label: "Ticket nº " + ticket.id.toString(),
            description: (
              <div>
                <p>
                  {ticket.contact.number}
                  <br />
                  {ticket.lastMessage}
                </p>
                <button
                  className={classes.button}
                  onClick={() => handleCardClick(ticket.uuid)}
                >
                  Ver Ticket
                </button>
              </div>
            ),
            title: ticket.contact.name,
            draggable: true,
            href: "/tickets/" + ticket.uuid,
          })),
          style: { backgroundColor: tag.color, color: "white" },
        };
      }),
    ];

    setFile({ lanes });
  }, [tags, tickets, classes.button]);

  const handleCardClick = (uuid) => {
    history.push("/tickets/" + uuid);
  };

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
      toast.success("Ticket Tag Removido!", {
        style: {
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(132, 9, 204)",
          fontWeight: "bold",
          border: "1px solid rgb(132, 9, 204)",
          borderRadius: "10px",
        },
      });
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
      toast.success("Ticket Tag Adicionado com Sucesso!", {
        style: {
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(132, 9, 204)",
          fontWeight: "bold",
          border: "1px solid rgb(132, 9, 204)",
          borderRadius: "10px",
        },
      });
    } catch (err) {
      console.error("Error moving card:", err);
    }
  };

  // Add useEffect hooks to fetch data and populate board
  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    if (tickets.length > 0 || tags.length > 0) {
      popularCards();
    }
  }, [tickets, tags, popularCards]);

  return (
    <div className={classes.root}>
      <Board
        data={file}
        onCardMoveAcrossLanes={handleCardMove}
        style={{ backgroundColor: "rgba(252, 252, 252, 0.03)", width: '100%' }}
      />
    </div>
  );
};

export default Kanban;